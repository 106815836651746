import { Center, useMantineTheme } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

export function SocialIconCopy({ copied }: { copied: boolean }) {
  const theme = useMantineTheme();
  const { background } = theme.fn.variant({
    color: copied ? 'green' : 'gray',
    variant: 'filled',
  });

  const { color } = theme.fn.variant({
    color: copied ? 'green' : 'gray',
    variant: 'filled',
  });

  return (
    <Center style={{ height: '100%', color, width: '100%', background, borderRadius: '100%' }}>
      {copied ? <IconCheck /> : <IconCopy />}
    </Center>
  );
}
