import {
  Button,
  ButtonProps,
  Center,
  createStyles,
  List,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  IconArrowRight,
  IconCoin,
  IconCoins,
  IconHighlight,
  IconMoneybag, IconReportMoney,
  IconShoppingBag,
  IconShoppingCart,
  IconUsers,
} from '@tabler/icons-react';
import React, { MouseEvent } from 'react';
import { useAmor } from '~/components/Amor/useAmor';
import { CurrencyIcon } from '~/components/Currency/CurrencyIcon';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { ContainerGrid } from '~/components/ContainerGrid/ContainerGrid';

const useStyles = createStyles((theme) => ({
  featureCard: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
  },
}));

const getEarnings = (): (FeatureCardProps & { key: string })[] => [
  {
    key: 'purchase',
    icon: <IconCoin size={32} />,
    title: 'Purchase',
    description: 'Purchase Amor directly',
    btnProps: {
      href: '/purchase/amor',
      children: 'Buy now',
    },
  },
  {
    key: 'sell',
    icon: <IconReportMoney size={32} />,
    title: 'Stylish Sales',
    description: 'Sell your unique style that will lead the fashion world',
    btnProps: {
      href: '/selfie_posts/create',
      children: 'Create selfie posts',
    },
  },
  {
    key: 'tips',
    icon: <IconCoins size={32} />,
    title: 'Get tipped',
    description: 'Get Amor along with feedback from men',
    btnProps: {
      href: '/selfie_posts/create',
      children: 'Create selfie posts',
    },
  },
  {
    key: 'bounties',
    icon: <IconMoneybag size={32} />,
    title: 'Bounties',
    description: 'Enter your work into the bounty to win Amor',
    btnProps: {
      href: '/bounties',
      children: 'Explorer bounties',
    },
  },
  // {
  //   key: 'referrals',
  //   icon: <IconUsers size={32} />,
  //   title: 'Referrals',
  //   description: 'You & your friends can earn more Amor!',
  //   btnProps: {
  //     href: '/user/account#referrals',
  //     children: 'Invite a friend',
  //   },
  // },
];

export const EarningAmor = ({ asList, withCTA }: Props) => {
  const earnings = getEarnings();

  return (
    <Stack spacing={20}>
      {asList ? (
        <FeatureList data={earnings} />
      ) : (
        <ContainerGrid gutter={20}>
          {earnings.map((item) => (
            <ContainerGrid.Col key={item.key} xs={12} md={3}>
              <FeatureCard {...item} withCTA={withCTA ?? item.withCTA} />
            </ContainerGrid.Col>
          ))}
        </ContainerGrid>
      )}
    </Stack>
  );
};

const getSpendings = ({
  username,
  balance,
}: {
  username: string;
  balance: number;
}): (FeatureCardProps & { key: string })[] => [
  {
    key: 'tip',
    icon: <IconCoins size={32} />,
    title: 'Tip an selfist',
    description: 'Support an selfist you love!',
    btnProps: {
      href: '/selfie_posts',
      children: 'View selfists',
      rightIcon: <IconArrowRight size={14} />,
    },
  },
  {
    key: 'bounties',
    icon: <IconMoneybag size={32} />,
    title: 'Bounties',
    description: 'Create a bounty and award Amor',
    btnProps: {
      href: '/bounties/create',
      children: 'Create a bounty',
      rightIcon: <IconArrowRight size={14} />,
    },
  },
  // {
  //   key: 'showcase',
  //   icon: <IconHighlight size={32} />,
  //   title: 'Get showcased',
  //   description: 'Boost your model to our homepage',
  //   btnProps: {
  //     target: '_blank',
  //     rel: 'noreferrer nofollow',
  //     href: `https://forms.clickup.com/8459928/f/825mr-8431/V3OV7JWR6SQFUYT7ON?LovedByMen%20Username=${encodeURIComponent(
  //       username ?? ''
  //     )}&Amor%20Available=${balance ?? 0}`,
  //     children: 'Contact us',
  //     rightIcon: <IconArrowRight size={14} />,
  //   },
  // },
  {
    key: 'merch',
    icon: <IconShoppingCart size={32} />,
    title: 'Shop merch',
    description: 'Tons of fun stickers to choose from...',
    btnProps: {
      disabled: true,
      children: 'COMING SOON',
    },
  },
  {
    key: 'badges',
    icon: <IconShoppingBag size={32} />,
    title: 'Shop badges and cosmetics',
    description: 'Make your profile stand out!',
    btnProps: {
      disabled: true,
      children: 'COMING SOON',
    },
  },
];

export const SpendingAmor = ({ asList, withCTA }: Props) => {
  const currentUser = useCurrentUser();
  const { balance } = useAmor();
  // const open = useGenerationStore((state) => state.open);
  const spendings = getSpendings({
    username: currentUser?.username ?? '',
    balance: balance ?? 0,
  });

  return (
    <Stack spacing={20}>
      <Stack spacing={4}>
        <Title order={2}>Spend Amor</Title>
        <Text>Got some Amor? Here&rsquo;s what you can do with it</Text>
      </Stack>
      {asList ? (
        <FeatureList data={spendings} />
      ) : (
        <ContainerGrid gutter={20}>
          {spendings.map((item) => (
            <ContainerGrid.Col key={item.key} xs={12} md={3}>
              <FeatureCard {...item} withCTA={withCTA ?? item.withCTA} />
            </ContainerGrid.Col>
          ))}
        </ContainerGrid>
      )}
    </Stack>
  );
};

type Props = { asList?: boolean; withCTA?: boolean };

type FeatureCardProps = {
  title: string;
  description: string;
  icon: React.ReactNode;
  btnProps: ButtonProps & {
    href?: string;
    component?: 'a' | 'button';
    target?: string;
    rel?: string;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
  };
  withCTA?: boolean;
};

export const FeatureCard = ({ title, description, icon, btnProps, withCTA }: FeatureCardProps) => {
  const { classes } = useStyles();

  if (!withCTA && btnProps.disabled) return null;

  return (
    <Paper withBorder className={classes.featureCard} h="100%">
      <Stack spacing={4} p="md" align="center" h="100%">
        <Center>{icon}</Center>
        <Text weight={500} size="xl" align="center" transform="capitalize">
          {title}
        </Text>
        <Text color="dimmed" align="center">
          {description}
        </Text>
        {withCTA && <Button component="a" mt="auto" w="100%" {...btnProps} />}
      </Stack>
    </Paper>
  );
};

export const FeatureList = ({ data }: { data: FeatureCardProps[] }) => {
  return (
    <List
      listStyleType="none"
      spacing={8}
      icon={<CurrencyIcon currency="AMOR" size={20} style={{ verticalAlign: 'middle' }} />}
    >
      {data.map((item, index) => (
        <List.Item key={index}>
          <Stack spacing={0}>
            <Text weight={590} transform="capitalize">
              {item.title}
              {item.btnProps.disabled ? ' (Coming Soon)' : ''}
            </Text>
            <Text color="dimmed">{item.description}</Text>
          </Stack>
        </List.Item>
      ))}
    </List>
  );
};
