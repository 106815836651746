import dayjs from 'dayjs';
import useIsClient from '~/hooks/useIsClient';
import {
  Center,
  createStyles,
  Divider,
  Group,
  Loader,
  Paper,
  RingProgress,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import React from 'react';
import { EarningAmor } from '~/components/Amor/FeatureCards/FeatureCards';
import { CurrencyBadge } from '~/components/Currency/CurrencyBadge';
import { Currency } from '@prisma/client';
import { IconInfoCircle } from '@tabler/icons-react';
import { trpc } from '~/utils/trpc';
import { useCurrentUser } from '~/hooks/useCurrentUser';

export const EarnAmor = () => {
  const currentUser = useCurrentUser();

  const useStyles = createStyles((theme) => ({
    tileCard: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  }));

  const { data: rewards = [], isLoading: loadingRewards } =
    trpc.user.userRewardDetails.useQuery(undefined);

  const { classes } = useStyles();

  return (
    <>
      <EarningAmor withCTA />

      <Paper withBorder className={classes.tileCard} h="100%">
        <Stack p="md">
          <Title order={3}>Other ways you can earn Amor</Title>
          {loadingRewards ? (
            <Center py="xl">
              <Loader />
            </Center>
          ) : (
            rewards.map((reward, i) => {
              const hasAwarded = reward.awarded !== -1;
              const last = i === rewards.length - 1;
              const awardedAmountPercent =
                reward.cap && hasAwarded ? reward.awarded / reward.cap : 0;

              return (
                <Stack key={reward.type} spacing={4}>
                  <Group position="apart" mih={30}>
                    <Group noWrap spacing="xs">
                      <CurrencyBadge
                        w={100}
                        currency={Currency.AMOR}
                        unitAmount={reward.awardAmount}
                      />
                      <Text>{reward.triggerDescription ?? reward.description}</Text>
                      {reward.tooltip && (
                        <Tooltip label={reward.tooltip} maw={250} multiline withArrow>
                          <IconInfoCircle size={20} style={{ flexShrink: 0 }} />
                        </Tooltip>
                      )}
                    </Group>
                    {reward.cap && (
                      <Group spacing={4}>
                        <Text color="dimmed" size="xs">
                          {hasAwarded
                            ? `${reward.awarded} / ${reward.cap.toLocaleString()} `
                            : `${reward.cap.toLocaleString()} `}{' '}
                          {reward.interval ?? 'day'}
                        </Text>
                        {hasAwarded && (
                          <RingProgress
                            size={30}
                            thickness={9}
                            sections={[
                              {
                                value: awardedAmountPercent * 100,
                                color: awardedAmountPercent === 1 ? 'green' : 'yellow.7',
                              },
                            ]}
                          />
                        )}
                      </Group>
                    )}
                  </Group>
                  {!last && <Divider mt="xs" />}
                </Stack>
              );
            })
          )}
        </Stack>
      </Paper>
    </>
  );
};
