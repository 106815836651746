'use client';

import {
  Box,
  createStyles,
  MantineTheme,
  Text, // For styling
} from '@mantine/core';
import NextImage from 'next/image';
import { UserCard } from '~/components/Home/UserCard';

export const FloatingComponents: React.FC<{}> = () => {
  const { classes } = useStyles();

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 20,
        width: '100%',
        height: 'calc(100vh - 300px)',
        minHeight: '600px',
      })}
    >
      <div
        style={{
          position: 'absolute',
          left: '40%',
          top: '45px',
          zIndex: 0,
          width: 120,
          height: 120,
          marginLeft: -60,
          padding: 0,
          animation: 'levitate 11s ease infinite 1s',
        }}
      >
        <NextImage
          style={{ borderRadius: '15px' }}
          alt="Professional camera"
          src="/images/hero-card2.png"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <UserCard className="absolute border-none" />
      <Box className={classes.logo}>
        <img src="/images/logo.png" alt="lovedbymen logo" style={{ height: '100%' }} />
      </Box>
      <div
        style={{
          position: 'absolute',
          left: '40%',
          bottom: '0px',
          zIndex: 0,
          width: 200,
          height: 200,
          marginLeft: -100,
          padding: 0,
          animation: 'levitate 12s ease infinite 1s',
        }}
      >
        <NextImage
          style={{ borderRadius: '15px' }}
          alt="Woman listening to music"
          src="/images/hero-card1.png"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div
        style={{
          position: 'absolute',
          left: '405px',
          bottom: '49%',
          zIndex: 0,
          width: 'clamp(20%, 40%, 80%)', // Responsive width that decreases with smaller screens but doesn't go below 20%
          height: 175,
          marginBottom: -50,
          padding: 0,
          animation: 'levitate 10s ease infinite 1s',
        }}
      >
        <NextImage
          style={{ borderRadius: '15px' }}
          alt="Woman listening to music"
          src="/images/hero-card3.png"
          layout="fill"
          objectFit="cover"
        />
        <Text
          className="absolute"
          style={{ color: '#f7d079', top: 5, left: 10, fontFamily: 'fantasy' }}
        >
          Fashion Club
        </Text>
      </div>
    </Box>
  );
};

const useStyles = createStyles((theme: MantineTheme) => ({
  logo: {
    position: 'absolute',
    left: '260px',
    top: '50%',
    marginTop: -60,
    zIndex: 10,
    width: 120,
    height: 120,
    animation: 'levitate 18s ease infinite',
    border: 'none',
    '@keyframes levitate': {
      '0%': {
        transform: 'translateY(0)',
      },
      '30%': {
        transform: 'translateY(-10px)',
      },
      '50%': {
        transform: 'translateY(4px)',
      },
      '70%': {
        transform: 'translateY(-15px)',
      },
      '100%': {
        transform: 'translateY(0)',
      },
    },
  },
}));
