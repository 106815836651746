'use client';

import React, { useState, FC } from 'react';
import { Card, Text, Button, Avatar, Group, Stack } from '@mantine/core';
import { LoginRedirect } from '~/components/LoginRedirect/LoginRedirect';

interface UserTwitterCardProps {
  className?: string;
}

export const UserCard: FC<UserTwitterCardProps> = ({ className }) => {
  const [isFollowed, setIsFollowed] = useState(false);

  return (
    <Card
      radius="md"
      withBorder
      className={className}
      style={{
        maxWidth: 300,
        left: '0px',
        top: '50%',
        marginTop: -73,
        animation: 'levitate 9s ease infinite 1s',
      }}
    >
      <Card.Section style={{ padding: '16px 16px 0px 16px' }}>
        <Group position="apart" align="center">
          <Group spacing="sm">
            <Avatar
              src="https://image.lovedbymen.com/056ba08e-0dea-4f27-a1f0-46bdf29f5d8d"
              alt="Zoey Lang"
              radius="xl"
              size="md"
            />
            <Stack spacing={0}>
              <Text size="sm" weight={500}>
                anigifford
              </Text>
              <Text size="xs" color="dimmed">
                @aninagifford
              </Text>
            </Stack>
          </Group>
          <LoginRedirect reason="perform-action">
            <Button
              variant={isFollowed ? 'outline' : 'filled'}
              size="xs"
              radius="xl"
              onClick={() => setIsFollowed(!isFollowed)}
              color={isFollowed ? 'gray' : 'gold'}
            >
              {isFollowed ? 'Unfollow' : 'Follow'}
            </Button>
          </LoginRedirect>
        </Group>
      </Card.Section>

      <Card.Section style={{ padding: '0 16px 5px 16px' }}>
        <Text size="sm" mt="xs" color="dimmed">
          Elegant, versatile, confident{' '}
          <span aria-label="confetti" role="img">
            🎉
          </span>
        </Text>
      </Card.Section>

      <Card.Section style={{ padding: '0 16px 16px 16px' }}>
        <Group mt="sm" spacing="xs">
          <Group spacing={2}>
            <Text size="sm" weight={500}>
              4
            </Text>
            &nbsp;
            <Text size="sm" color="dimmed">
              Following
            </Text>
          </Group>
          <Group spacing={2}>
            <Text size="sm" weight={500}>
              5
            </Text>
            &nbsp;
            <Text size="sm" color="dimmed">
              Followers
            </Text>
          </Group>
        </Group>
      </Card.Section>
    </Card>
  );
};
