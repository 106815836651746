import { Text } from '@mantine/core';

export function JoinToday() {
  return (
    <>
      <Text italic style={{ fontSize: 35, textAlign: 'center', marginBottom: 20, marginTop: 25 }}>
        Join{' '}
        <Text span c="gold">
          LovedByMen
        </Text>{' '}
        today to immerse yourself in a world where fashion is not just seen, but celebrated, shared,
        and refined through genuine cultural exchange.
      </Text>
    </>
  );
}
