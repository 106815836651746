import { Box, Button, createStyles, Grid, Paper, Text, Stack } from '@mantine/core';
import { containerQuery } from '~/utils/mantine-css-helpers';
import {
  IconArrowRight,
  IconArticle,
  IconAward,
  IconBoxModel,
  IconCameraSelfie,
  IconCash,
  IconClothesRack,
  IconClubs,
  IconEmpathize,
  IconHeartHandshake,
  IconMoodBoy,
  IconRun,
  IconShare,
  IconWand,
} from '@tabler/icons-react';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useRouter } from 'next/router';
import { EarnAmor } from '~/components/EarnAmor/EarnAmor';

export function HowItWorks() {
  const { cx, classes } = useStyle();
  const currentUser = useCurrentUser();
  const router = useRouter();

  return (
    <>
      <Text
        style={{
          fontFamily: 'cursive',
          fontSize: 35,
          textAlign: 'center',
          marginBottom: 20,
          marginTop: 25,
        }}
      >
        How{' '}
        <Text span c="gold.7">
          LovedByMen
        </Text>{' '}
        Works?
      </Text>
      <Grid>
        {items.map(({ title, text, icon, buttonText, link }, index) => (
          <Grid.Col span={12} sm={6} md={6} lg={4} xl={3} key={index}>
            <Paper withBorder className={cx(classes.card)} h="100%">
              <div style={{ minHeight: 160 }}>
                <Text className={classes.highlightColor} size="xl" style={{ marginBottom: 10 }}>
                  {title}
                </Text>
                <Text size="lg">{text}</Text>
              </div>
              <Button
                component="a"
                onClick={() => {
                  if (currentUser && link !== '') router.push(link);
                  else if (!currentUser) {
                    router.push('/login');
                  }
                }}
                variant="outline"
                rel="noopener noreferrer"
                rightIcon={<IconArrowRight size={18} />}
                styles={(theme) => ({
                  root: {
                    border: 0,
                    height: 42,
                    padding: 0,
                    marginTop: 10,
                    '&:hover': {
                      backgroundColor: 'inherit',
                      color: 'inherit',
                      boxShadow: 'none',
                    },
                  },
                })}
              >
                {buttonText}
              </Button>
              <div style={{ position: 'absolute', bottom: 0, right: 10, opacity: 0.1, zIndex: 0 }}>
                {icon}
              </div>
            </Paper>
          </Grid.Col>
        ))}
      </Grid>
      <Stack spacing="xl">
        <div style={{ textAlign: 'center', marginTop: 25 }}>
          <Text
            c="gold.7"
            onClick={() => {
              router.push('/selfists-program');
            }}
            style={{
              border: 0,
              marginTop: 10,
              cursor: 'pointer',
              height: 52,
              padding: 0,
              fontSize: 35,
              textDecoration: 'underline',
            }}
          >
            How to earn Amor as a selfist?
          </Text>
          <Text
            style={{
              color: 'grey',
              border: 0,
              fontStyle: 'italic',
              height: 20,
              padding: 0,
              fontSize: 15,
            }}
          >
            Selfist is a selfie creator on LovedByMen, where users can buy or cash out Amor, the
            platform's virtual currency.
          </Text>
        </div>
        <EarnAmor />
      </Stack>
    </>
  );
}

const useStyle = createStyles((theme) => ({
  card: {
    position: 'relative',
    borderRadius: theme.radius.md,
    padding: theme.spacing.lg,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
  },
  highlightColor: {
    color: theme.colorScheme === 'dark' ? 'white' : 'black',
    zIndex: 10,
  },
}));

const items: {
  title: string;
  text: string;
  icon: React.ReactNode;
  buttonText: string;
  link: string;
}[] = [
  {
    title: 'Post Your Selfies',
    text: 'Capture and share your unique style through fashion-forward selfies, setting a price to earn cash from your best looks.',
    icon: <IconCameraSelfie size={62} />,
    buttonText: 'Post selfies',
    link: '/selfie_posts/create',
  },
  {
    title: 'Receive Genuine Reactions',
    text: 'Get instant feedback on your selfies from fashion-savvy men, exclusively on our platform where only men can react.',
    icon: <IconMoodBoy size={62} />,
    buttonText: 'Explorer reactions',
    link: '/selfie_posts',
  },
  {
    title: 'Express Your Fashion Ideas',
    text: 'Write and publish articles about your fashion inspirations, trends you love, or personal style tips.',
    icon: <IconArticle size={62} />,
    buttonText: 'Write an article',
    link: '/articles/create?categoryId=1',
  },
  {
    title: 'Earn and Withdraw Tips',
    text: 'Accumulate tips for your selfies and articles, and convert them into cash through our Selfists Program.',
    icon: <IconCash size={62} />,
    buttonText: 'Join selfists program',
    link: '/selfists-program',
  },
  {
    title: 'Create and Manage Your Fashion Club',
    text: 'Start your own fashion club, gather followers, and build a community around your unique style.',
    icon: <IconClubs size={62} />,
    buttonText: 'Coming soon',
    link: '',
  },
  {
    title: 'Discover Authentic Fashion Products',
    text: 'Explore and connect with original fashion products and trends that resonate with your style.',
    icon: <IconClothesRack size={62} />,
    buttonText: 'Discover fashion products',
    link: '/models',
  },
  {
    title: 'Host a Bounty for Your Perfect Look',
    text: 'Set up a bounty to crowdsource ideas for the ideal fashion style for any occasion.',
    icon: <IconRun size={62} />,
    buttonText: 'Create a bounty',
    link: '/bounties/create',
  },
  {
    title: 'Compete in Bounties to Win',
    text: 'Participate in fashion bounties to showcase your creativity and win exciting prizes.',
    icon: <IconAward size={62} />,
    buttonText: 'Compete in bounty',
    link: '/bounties',
  },
];
